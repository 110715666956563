import Cookies from 'js-cookie';
import axios from 'axios';
import { addClass, removeClass, escapeString } from './helper';
var key_of_cookie_name = 'zinjachannel_logged_in_keyname';
var key_of_cookie_value = 'zinjachannel_logged_in';
var header_button_wrapper_selector = ".header_account_info";
var header_username_area_selector = ".header_account_info--account_name";
function _setStatusToNoLogin() {
    var el_for_button_wrapper = document.querySelectorAll(header_button_wrapper_selector);
    el_for_button_wrapper.forEach(function (el) {
        removeClass(el, 'is_logged_in');
        addClass(el, 'is_not_login');
    });
}
function _setStatusToLoggedIn(username) {
    var el_for_username_area = document.querySelectorAll(header_username_area_selector);
    el_for_username_area.forEach(function (el) {
        el.innerText = escapeString(username);
    });
    var el_for_button_wrapper = document.querySelectorAll(header_button_wrapper_selector);
    el_for_button_wrapper.forEach(function (el) {
        removeClass(el, 'is_not_login');
        addClass(el, 'is_logged_in');
    });
}
/**
 * sessionStorageに保存している情報を破棄します
 */
export function clearInfo() {
    sessionStorage.removeItem('username');
}
/**
 * 認証状況を確認してヘッダのボタン状態を変更します。
 */
export function checkAuthStatus() {
    // セッション情報チェック
    var auth_key = Cookies.get(key_of_cookie_name) || '';
    var auth_val = Cookies.get(key_of_cookie_value) || '';
    if (auth_key === '' || auth_val === '') {
        clearInfo();
        _setStatusToNoLogin();
        return;
    }
    // 取得済みのアカウント情報があるか確認
    var username = sessionStorage.getItem('username');
    if (username !== null && username !== '') {
        _setStatusToLoggedIn(username);
        return;
    }
    axios.get('/wp-json/member/v1/account/user_info', {
        headers: {
            'zc-auth-cookie-key': auth_key,
            'zc-auth-cookie-val': auth_val,
        },
    }).then(function (res) {
        sessionStorage.setItem('username', res.data.display_name);
        _setStatusToLoggedIn(res.data.display_name);
    })
        .catch(function (_e) {
        // NOTHING TODO
        _setStatusToNoLogin();
    });
}
(function () {
    // https://stackoverflow.com/questions/588040/window-onload-vs-document-onload
    document.addEventListener('DOMContentLoaded', function () {
        checkAuthStatus();
    });
})();
