//
// common
//
import * as Helper from './helper';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './header-login-status';
// import { checkAuthStatus } from './header-login-status'
// checkAuthStatus()
/**
 * Viewportの変更
 * https://www.bricoleur.co.jp/blog/archives/2985
 */
export function checkScreenSize() {
    var ua_text = navigator.userAgent.toLowerCase();
    var breakpoint = 768;
    var viewport_for_mobile = "width=320";
    var viewport_for_others = "width=display-width,user-scalable=no,initial-scale=1.0";
    var is_exist_meta_object = document.querySelector("meta[name='viewport']") !== null;
    var target_meta_object;
    var screen_width = window.outerWidth;
    if (!is_exist_meta_object) {
        // https://stackoverflow.com/questions/18982228/how-to-add-meta-tag-in-javascript
        var meta = document.createElement('meta');
        meta.name = 'viewpoert';
        meta.content = '';
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
    target_meta_object = document.querySelector("meta[name='viewport']");
    if (/ip(hone|ad|od)/.test(ua_text)) {
        // documentElementはnullになり得る
        if (document.documentElement !== null) {
            screen_width = document.documentElement.clientWidth;
        }
    }
    var is_mobile = screen_width <= breakpoint;
    if (is_mobile) {
        target_meta_object.setAttribute('content', viewport_for_mobile);
    }
    else {
        target_meta_object.setAttribute('content', viewport_for_others);
    }
}
// リスナを登録し、即時実行する
window.addEventListener('resize', checkScreenSize);
window.addEventListener('orientationchange', checkScreenSize);
var ev = document.createEvent("UIEvents");
ev.initEvent('resize', true, true);
window.dispatchEvent(ev);
// header ----------------------------------------------------------------------
/**
 * toggleHeaderMenuForMobile モバイルメニューの表示切り替え
 */
export function toggleHeaderMenuForMobile() {
    var icon_doms = document.querySelectorAll('header .burger .burger-button-icon');
    var nav_dom = document.querySelector('#global-nav-mobile');
    var body_dom = document.querySelector('body');
    if (icon_doms === null || nav_dom === null || body_dom === null) {
        console.error('グローバルナビゲーションに適切なclassが設定されていません。');
        return;
    }
    // toggle icon visibility
    icon_doms.forEach(function (dom) {
        Helper.toggleClass(dom, 'hidden');
        Helper.toggleClass(dom, 'block');
    });
    // toggle global-navigation visibility
    Helper.toggleClass(nav_dom, 'hidden');
    // set offset to body element
    var el_offset = '';
    if (body_dom.style.marginTop === '') {
        var offsetTop = window.scrollY;
        el_offset = '-' + offsetTop + 'px';
    }
    // toggle scroll-lock in body element.
    if (body_dom.style.overflow === '') {
        disableBodyScroll(body_dom);
    }
    else {
        enableBodyScroll(body_dom);
    }
    // revert scroll position
    if (el_offset === '') {
        var offsetTop = body_dom.style.marginTop === null
            ? 0
            : parseInt(body_dom.style.marginTop, 10);
        body_dom.style.marginTop = el_offset;
        window.scrollTo({ top: offsetTop });
    }
    else {
        body_dom.style.marginTop = el_offset;
    }
}
